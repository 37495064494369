<template>
  <b-modal
    id="bug-reporter-modal"
    @close="close"
    title="Fehler Melden"
    @ok="submit"
    cancel-title="Abbrechen"
  >
    <form @submit="submit">
      <b-form-group
        label="Was wollten sie tun, als das Problem aufgetreten ist?"
      >
        <b-textarea placeholder="freiwillig" v-model="form.attempt" />
      </b-form-group>
      <b-form-group label="Wie hätte sich das System verhalten sollen?">
        <b-textarea placeholder="freiwillig" v-model="form.expectation" />
      </b-form-group>
      <b-form-group label="Was ist stattdessen passiert">
        <b-textarea placeholder="freiwillig" v-model="form.behavior" />
      </b-form-group>
    </form>
  </b-modal>
</template>
<script>
import gql from "graphql-tag";
export default {
  props: ["bugdata"],
  data() {
    return {
      form: {
        attempt: null,
        expectation: null,
        behavior: null,
      },
    };
  },
  watch: {
    bugdata(val) {
      if (val) {
        this.$bvModal.show("bug-reporter-modal");
      }
    },
  },
  methods: {
    resetForm() {
      this.form = {
        attempt: null,
        expectation: null,
        behavior: null,
      };
    },
    close() {
      this.$emit("close");
      this.resetForm();
    },
    async submit(e) {
      e.preventDefault();
      await this.$apollo.mutate({
        mutation: gql`
          mutation ReportBug($input: BugReportInput) {
            reportBug(input: $input) {
              id
            }
          }
        `,
        variables: {
          input: {
            ...this.form,
            location: window.location.href,
            debug_data: this.bugdata,
          },
        },
      });
      this.$bvModal.hide("bug-reporter-modal");
      this.$toast("Fehler gemeldet. Vielen dank!");
      this.resetForm();
    },
  },
};
</script>