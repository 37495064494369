import MainLayout from "@/layout/MainLayout.vue";

export const generalRoutes = [
  // main layout
  {
    path: "/",
    component: MainLayout,
    redirect: { name: 'home' },
    children: [
      {
        path: "/dashboard",
        name: "home",
        component: () => import("@/views/dashboard/Dashboard.vue"),
      },
      {
        path: "/file-editor/:id",
        name: "file-editor",
        component: () => import("@/views/dashboard/mvp.vue"),
        meta: { permissions: ["normalUser"] }
      },
      {
        path: "/benachrichtigungen",
        name: "notifications",
        component: () => import("@/views/dashboard/show/DashboardNotifications.vue"),
      },
      {
        path: "/favoriten",
        name: "favorites",
        component: () => import("@/views/dashboard/show/DashboardFavorites.vue"),
      },
      {
        path: "/ressorts",
        name: "ressorts",
        component: () => import("@/views/ressort/list/RessortList.vue"),
      },
      {
        path: "/filemanager",
        name: "filemanager",
        component: () => import("@/views/filemanager/FileManager.vue"),
        meta: { permissions: ["admin.files"] },
      },
      {
        path: "/support",
        name: "support",
        component: () => import("@/views/support/list/SupportDashboard.vue"),
      },
      {
        path: "/support/anleitungen",
        name: "support.tutorials",
        component: () => import("@/views/support/show/SupportTutorials.vue"),
      },
      {
        path: "/support/faq",
        name: "support.faq",
        component: () => import("@/views/support/show/SupportFaq.vue"),
      },
      {
        path: "/support/ticket/:id",
        name: "support.ticket.show",
        component: () => import("@/views/support/show/SupportShow.vue"),
      },
      {
        path: "/global-search",
        name: "global-search",
        component: () => import("@/views/global-search/GlobalSearchPage.vue")
      },
      {
        path: "/content/:slug",
        name: "content-page",
        component: () => import("@/views/contentPages/Show.vue"),
      },
    ]
  },
  {
    path: "/test",
    name: "test",
    component: () => import("@/views/Test.vue"),
  },
];
