// infos: https://bootstrap-vue.org/docs/components/toast
export default {
  methods: {
    $toast(content, title = null) {
      if (title) {
        this.$bvToast.toast(content, {
          title: title,
          toaster: "b-toaster-bottom-center",
          solid: true,
          variant: 'success',
          appendToast: true,
          noCloseButton: true,
          autoHideDelay: 5000,
        });
      }
      // we dont have a title, dont add title div
      else {
        this.$bvToast.toast(content, {
          toaster: "b-toaster-bottom-center",
          solid: true,
          variant: 'success',
          appendToast: true,
          noCloseButton: true,
          autoHideDelay: 5000,
        });
      }
    },
  },
};
