<template>
  <div>
    <!-- <template v-if="permissions['admin.show']"> -->
      <span>Version: {{ version }}</span>
      <b-button @click="() => openBugReporter()"
        ><i class="fal fa-bug"></i> Bug Melden</b-button
      >
      <bug-reporter :bugdata="bugdata" @close="bugdata = null" />
    <!-- </template>
    <template v-else> -->
      <router-link
        :to="{ name: 'support' }"
        class="mr-2 hover-orange"
        style="padding: 8px 0; display: inline-block"
        ><i class="fa-light fa-circle-question mr-1"></i>Hilfe</router-link
      >
    <!-- </template> -->
  </div>
</template>
<script>
import BugReporter from "../bugs/BugReporter.vue";

setInterval(() => {
  if (console.logs) {
    while (console.logs.length > 100) {
      console.logs.shift();
    }
  }
}, 1000);
export default {
  data() {
    return {
      bugdata: null,
    };
  },
  components: { BugReporter },
  computed: {
    version() {
      return process.env.VUE_APP_VERSION;
    },
  },
  methods: {
    async openBugReporter(err = undefined) {
      this.bugdata = null;
      await this.$nextTick();
      const logs = [...(console.logs || [])];
      // Sometimes objects logged to the console contain circular references
      // and can't be serialized because of that so we have to build the json
      // string ourselves.
      let json = [];
      json.push('{"console": [');
      for (let i = 0; i < logs.length; i++) {
        try {
          json.push(JSON.stringify(logs[i]));
        } catch (e) {
          console.log(logs[i]);
          json.push(
            `["${logs[i][0]}", "${logs[
              i
            ][1].toISOString()}", "Could not be Stringified"]`
          );
        }
        if (i < logs.length - 1) {
          json.push(", ");
        }
      }
      json.push("], ");
      if (console.logs) console.logs.length = 0;
      if (err) {
        json.push('"cause": ');
        json.push(JSON.stringify({ ...err }));
      } else {
        json.push('"cause": null');
      }
      json.push("}");
      json = json.join("");
      this.bugdata = json;
    },
  },
  loadPermissions() {
    return ["admin.show"];
  },
};
</script>
