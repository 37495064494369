import Vue from "vue";
import Router from "vue-router";
import routes from "./routes";

Vue.use(Router);

/**
 * Create a new router instance.
 *
 * @param  {Array} routes
 * @return {Router}
 */
function make(routes) {
  const router = new Router({
    routes,
    base: process.env.BASE_URL,
    scrollBehavior,
    mode: "history",
  });

  router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById("loading-bg");
    if (appLoading) {
      appLoading.style.display = "none";
    }
  });

  return router;
}

/**
 * @param  {Route} to
 * @param  {Route} from
 * @param  {Object|undefined} savedPosition
 * @return {Object}
 */
function scrollBehavior(to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition;
  }

  const position = {};

  if (to.hash) {
    position.selector = to.hash;
  }

  if (to.matched.some((m) => m.meta.scrollToTop)) {
    position.x = 0;
    position.y = 0;
  }

  return position;
}

// const router = new Router({
//   mode: 'history',
//   fallback: false,
//   scrollBehavior: () => ({ y: 0 }),
//   routes,
// });

// refresh userdata on every route change!
// router.beforeEach((to, from, next) => {
//   if (store.getters['authenticated'] && (to.name !== from.name || to.path !== from.path)) {
//     store.dispatch("refreshAuth").then(() => {
//       next()
//     })
//   } else {
//     next()
//   }
// });

// https://markus.oberlehner.net/blog/implementing-a-simple-middleware-with-vue-router/
// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
// function nextFactory(context, middleware, index) {
//     const subsequentMiddleware = middleware[index];
//     // If no subsequent Middleware exists,
//     // the default `next()` callback is returned.
//     if (!subsequentMiddleware) return context.next;

//     return (...parameters) => {
//       // Run the default Vue Router `next()` callback first.
//       context.next(...parameters);
//       // Then run the subsequent Middleware with a new
//       // `nextMiddleware()` callback.
//       const nextMiddleware = nextFactory(context, middleware, index, 1);
//       subsequentMiddleware({ ...context, next: nextMiddleware });
//     };
//   }

//   router.beforeEach((to, from, next) => {
//     if (to.meta.middleware) {
//       const middleware = Array.isArray(to.meta.middleware)
//         ? to.meta.middleware
//         : [to.meta.middleware];

//       const context = {
//         from,
//         next,
//         router,
//         to,
//       };
//       const nextMiddleware = nextFactory(context, middleware, 1);

//       return middleware[0]({ ...context, next: nextMiddleware });
//     }

//     return next();
//   });

/**
 * Apply beforeEnter guard to the routes.
 *
 * @param  {Array} routes
 * @param  {Function} beforeEnter
 * @return {Array}
 */
function routeGuard(routes, beforeEnter, afterEnter = null) {
  return routes.map((route) => ({ ...route, beforeEnter, afterEnter }));
}

/**
 * Redirect to login if guest.
 *
 * @param  {Array} routes
 * @return {Array}
 */
function authGuard(routes) {
  return routeGuard(routes, (to, from, next) => {
    if (!localStorage.getItem("auth-token")) {
      next({ name: "login", params: { last: to.name } });
    } else {
      next();
    }
  });
}

/**
 * Redirect home if authenticated.
 *
 * @param  {Array} routes
 * @return {Array}
 */
function guestGuard(routes) {
  return routeGuard(routes, (to, from, next) => {
    if (localStorage.getItem("auth-token")) {
      next({ name: "home" });
    } else {
      next();
    }
  });
}

/**
 * Redirect home if authenticated.
 *
 * @param  {Array} routes
 * @return {Array}
 */
function externalGuard(routes) {
  return routeGuard(routes, (to, from, next) => {
    next();
  });
}

const router = make(routes({ authGuard, guestGuard, externalGuard }));
router.beforeEach(async (to, from, next) => {
  if (to.meta && to.meta.permissions) {
    let vueRoot = await window.vueRoot;
    if (await vueRoot.$checkAllPermissions(to.meta.permissions)) {
      next();
      return;
    } else {
      vueRoot.$toastFail("Nicht genug Berechtigungen"); //TODO: do something more elaborate here
      return false;
    }
  }
  next();
});

export default router;
