<template>
  <router-link class="global-search-result" :to="getUrl(value)">
    <div class="icon-container">
      <i :class="getIcon(value)"></i>
    </div>

    <div class="info-container">
      {{ getTitle(value) }}

      <p v-if="getContent(value)">{{ getContent(value) }}</p>
    </div>
  </router-link>
</template>

<script>
const typenameMapping = {
  Gremium: {
    icon: "fa-light fa-screen-users",
    title: item => item.name,
    url: item => `/gremium/${item.route_slug}`
  },
  News: {
    icon: "fa-light fa-comment",
    title: item => item.title,
    url: item => {
      const gremium = item.gremium.length > 0 ? item.gremium[0] : null;
      const gremium_slug = gremium?.route_slug;
      const prefix =
        typeof gremium_slug === "string"
          ? `/gremium/${gremium_slug}/news`
          : `/news`;

      return `${prefix}/${item.route_slug}`;
    }
  },
  User: {
    icon: "fa-light fa-user",
    title: item => {
        let suffix = item.company ? ` (${item.company.name1})` : '';

        return `${item.first_name} ${item.last_name}${suffix}`;
    },
    url: item => `/benutzer/${item.id}`
  },
  TopicComment: {
    icon: "fa-light fa-poll-people",
    title: item => `${item.user.first_name} ${item.user.last_name}`,
    content: item => item.content.text_content,
    url: item => `/gremium/${item.topic.forum.gremium_route_slug}/forum/${item.topic.route_slug}/comment/${item.id}`
  },
  File: {
    icon: "fa-light fa-file",
    title: item => item.name,
    url: item => ""
  },
  Company: {
    icon: "fa-light fa-buildings",
    title: item => item.name1,
    url: item => `/firma/${item.id}`
  },
  DsagEvent: {
    icon: "fa-light fa-calendar",
    title: item => item.name,
    url: item => `/event/${item.route_slug}`
  },
  ForumTopic: {
    icon: "fa-light fa-calendar",
    title: item => item.title,
    url: item => `/gremium/${item.forum.gremium_route_slug}/forum/${item.route_slug}`
  },
  CompanyPartnerProfile: {
    icon:"fa-light fa-buildings",
    title: item => item.comp.name1 + "(Partner)",
    url: item => `/partner/${item.id}`
  }
};

export default {
  name: "GlobalSearchResult",
  props: ["value"],
  methods: {
    getIcon: item => {
      const t = item.__typename;
      return t in typenameMapping
        ? typenameMapping[t].icon + " fa-fw"
        : undefined;
    },
    getTitle: item => {
      const t = item.__typename;
      return t in typenameMapping ? typenameMapping[t].title(item) : undefined;
    },
    getUrl: item => {
      const t = item.__typename;
      return t in typenameMapping ? typenameMapping[t].url(item) : "";
    },
    getContent: item => {
      const t = item.__typename;
      const fn = typenameMapping[t]?.content;
      return fn ? fn(item) : null;
    },
  }
};
</script>
