const notificationType = {
  gremiumStatusChanged: "GREMIUM_STATUS_CHANGED",
  gremiumShoulbeDeleted: "GREMIUM_SHOULD_BE_DELETED",
  contactPartnerElectionImminent: "CONTACT_PARTNER_ELECTION_IMMINENT",
  commentCreated: "COMMENT_CREATED",
  topicCreated: "FORUM_TOPIC_CREATED",
  newsItemCreated: "NEWS_ITEM_CREATED",
  eventInvite: "INVITED_TO_EVENT",
  eventOnlineAccessOpen: "EVENT_ONLINE_ACCESS_OPEN",
  eventChanged: "EVENT_CHANGED",
  movedUpFromWaitingList: "EVENT_APPLICATION_MOVED_UP",
  manuallyAddedToEvent: "MANUAL_EVENT_APPLICATION",
  eventAnnounced: "EVENT_ANNOUNCED",
  eventApplicationsOpened: "EVENT_APPLICATIONS_OPENED",
  eventCanceled: "EVENT_CANCELED",
  custom: "CUSTOM_SYSTEM_NOTIFICATION",
  eventApplicationSuccesful: "EVENT_APPLICATION_SUCCESSFULL",
  eventApplicationCanceled: "EVENT_APPLICATION_CANCELED",
  eventStartImminent: "EVENT_START_IMMINENT",
  userShouldCheckProfile: "USER_SHOULD_CHECKP_ROFILE",
  companyShouldCheckProfile: "COMPANY_SHOULD_CHECK_PROFILE",
  newContactRequest: "NEW_CONTACT_REQUEST",
  newChatInvite: "NEW_CHAT_INVITE",
  newMembershipApplication: "NEW_MEMBERSHIP_APPLICATION",
  membershipApplicationVotesOpen: "MEMBEERSHIP_APPLICTAION_VOTE_OPEN",
  membershipApplicationVoteComplete: "MEMBERSHIP_APPLICATION_VOTE_COMPLETE",
  companyMembershipStateChanged: "COMPANY_MEMBERSHIP_APPLICATION_STATE_CHANGED",
  userAddedToCompany: "USER_ADDED_TO_COMPANY",
  membershipMessageSent: "MEMBERSHIP_MESSAGE_SENT",
  newTicket: "NEW_TICKET",
  ticketUpdated: "TICKET_UPDATED",
  GremiumFunctionaryAdded: "GREMIUM_FUNCTIONARY_ADDED",
  MembershipApplicationSuccesful: "MEMBERSHIP_APPLICATION_SUCCESSFULL",
  MemberhsipApplicationDenied: "MEMBERSHIP_APPLICATION_DENIED",
  MemberhsipApplicationSubmitted: "COMPANY_MEMBERSHIP_APPLICATION_SUBMITTED",
  CompanyOrphaned: "COMPANY_ORPHANED",
  SurveyPublished: "SURVEY_PUBLISHED",
  NewMessageInSupportTicket: "NEW_MESSAGE_IN_SUPPORT_TICKET",
  NewSupportTicket: "NEW_SUPPORT_TICKET",
  SupportTicketUpdated: "SUPPORT_TICKET_UPDATED",
  TicketAssigned: "TICKET_ASSIGEND",
  NewGremiumMember: "NEW_GREMIUM_MEMBER",
  GremiumMemberLeft: "GREMIUM_MEMBER_LEFT",
  GremiumDeactivated: "GREMIUM_DEACTIVATED",
  UserReportClosed: "USER_REPORT_CLOSED",
  UserReportUpdated: "USER_REPORT_UPDATED",
  OldCompanyMebershipApplication: "OLD_COMPANY_MEMBERSHIP_APPLICATION",
  SurveyCreated: "SURVEY_CREATED",
  EventProposalSubmitted: "EVENT_PROPOSAL_SUBMITTED",
  UserCanceledEventApplication: "USER_CANCELED_EVENT_APPLICATION",
  PartnerProfileBookingSuccesfull: "PARTNER_PROFILE_BOOKING_SUCCESFULL",
  MergerForFormerManagers: "COMPANY_MERGER_FOR_FORMER_MANAGERS",
  MergerForCurreentManagers: "COMPANY_MERGER_FOR_CURRENT_MANAGERS",
  GremiumFileUploaded: "GREMIUM_FILE_UPLOADED",
  GuestUser5DayReminder: "GUEST_USER_5_DAY_REMINDER",
  GuestUser15DayReminder: "GUEST_USER_15_DAY_REMINDER",
  GuestUser2DayReminder: "GUEST_USER_2_DAY_REMINDER",
  CompanyManagerProbationaryMembership: "COMPANY_MANAGER_PROBATIONARY_MEMBERSHIP",
  MVCompanyProbationaryMembership: "MV_COMPANY_PROBATIONARY_MEMBERSHIP",
  CompanyMergerEmailChangeRequest: "COMPANY_MERGER_EMAIL_CHANGE_REQUEST"
};

import NotificationLayout from "./templates/NotificationLayout";
import LinkNotificationComponent from "./templates/LinkNotification";
import TwoLinksNotificationComponent from "./templates/TwoLinksNotification";
import CommentCreatedNotificationComponent from "./templates/CommentCreatedNotification";
import CustomNotificationComponent from "./templates/CustomNotification";
import MemebershipMessageSentNotificationComponent from "./templates/MemebershipMessageSentNotification";
import TicketNotificationComponent from "./templates/TicketNotification";
import AcceptChatInviteNotificationComponent from "./templates/AcceptChatInviteNotification";
import moment from "moment";

class Notification {
  constructor({ notify_at, type, read_at, id }, icon) {
    this.notify_at = notify_at;
    this.type = type;
    this.read_at = read_at;
    this.icon = icon;
    this.id = id;
  }
  getComponent() {
    return NotificationLayout;
  }
}

class AcceptChatInviteNotification extends Notification {
  constructor(notificationData, user, chat) {
    super(notificationData, "fa-user-plus");
    this.user = user;
    this.chat = chat;
  }
  getComponent() {
    return AcceptChatInviteNotificationComponent;
  }
}

class CustomNotification extends Notification {
  constructor(notificationData, subject, content_id, prepend) {
    super(notificationData, "fa-exclamation");
    this.subject = subject;
    this.content_id = content_id;
    this.prepend = prepend
  }
  getComponent() {
    return CustomNotificationComponent;
  }
}

class MemebershipMessageSentNotification extends Notification {
  constructor(notificationData, data) {
    super(notificationData, "fa-comment");
    this.data = data;
  }
  getComponent() {
    return MemebershipMessageSentNotificationComponent;
  }
}

class TicketNotification extends Notification {
  constructor(notificationData, variant, ticket) {
    super(notificationData, "fa-comment");
    this.variant = variant;
    this.ticket = ticket;
  }
  getComponent() {
    return TicketNotificationComponent;
  }
}

class CommentNotification extends Notification {
  constructor(
    notificationData,
    topicTitle,
    topicSlug,
    gremiumTitle,
    gremiumSlug,
    commentId,
    user,
    gremium,
    replyTo = null
  ) {
    super(notificationData, "fa-comment");
    this.topicTitle = topicTitle;
    this.topicSlug = topicSlug;
    this.gremiumTitle = gremiumTitle;
    this.gremiumSlug = gremiumSlug;
    this.commentId = commentId;
    this.user = user;
    this.gremiumKind = gremiumKind(gremium)
    this.replyTo = replyTo;
  }
  getComponent() {
    return CommentCreatedNotificationComponent;
  }
}

class LinkNotification extends Notification {
  constructor(
    notificationData,
    textBeforeLink,
    linkText,
    linkTo,
    textAfterLink,
    icon
  ) {
    super(notificationData, icon);
    this.textBeforeLink = textBeforeLink;
    this.linkText = linkText;
    this.linkTo = linkTo;
    this.textAfterLink = textAfterLink;
  }

  getComponent() {
    return LinkNotificationComponent;
  }
}

class TwoLinksNotification extends Notification {
  constructor(
    notificationData,
    textBeforeLinks,
    firstLinkText,
    firstLinkTo,
    textBetweenLinks,
    secondLinkText,
    secondLinkTo,
    textAfterLinks,
    icon
  ) {
    super(notificationData, icon);
    this.textBeforeLinks = textBeforeLinks;
    this.firstLinkText = firstLinkText;
    this.firstLinkTo = firstLinkTo;
    this.textBetweenLinks = textBetweenLinks;
    this.secondLinkText = secondLinkText;
    this.secondLinkTo = secondLinkTo;
    this.textAfterLinks = textAfterLinks;
    this.icon = icon;
  }

  getComponent() {
    return TwoLinksNotificationComponent;
  }
}

function gremiumKind(gremium) {
  if (gremium.type === "PROJECT_ROOM") {
    return "Projektraum"
  }
  return "Gremium"
}

function gremiumKindWithArticle(gremium) {
  if (gremium.type === "PROJECT_ROOM") {
    return "Der Projektraum"
  }
  return "Das Gremium"
}

export function makeNotification({ notify_at, type, read_at, data, id }) {
  data = JSON.parse(data);
  switch (type) {
    case notificationType.gremiumStatusChanged:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        `Der Status des ${gremiumKind(data.gremium)}s`,
        data.gremium.name,
        { name: "gremium", params: { id: data.gremium.route_slug } },
        "wurde aktualisiert.",
        "fa-users"
      );
    case notificationType.gremiumShoulbeDeleted:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        gremiumKindWithArticle(data.gremium),
        data.gremium.name,
        { name: "gremium", params: { id: data.gremium.route_slug } },
        "ist seit 12 Monaten inaktiv und sollte gelöscht werden.",
        "fa-users"
      );
    case notificationType.SurveyPublished:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        "Die Umfrage ",
        data.survey.title,
        { name: "survey.show", params: { id: data.survey.route_slug } },
        " steht für Sie zur teilnahme bereit",
        "fa-square-poll-vertical"
      );
    case notificationType.SurveyCreated:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        "Die Umfrage ",
        data.survey.title,
        { name: "admin.surveys.edit", params: { slug: data.survey.route_slug } },
        " wurde erstellt und ist noch nicht veröffentlicht",
        "fa-square-poll-vertical"
      );
    case notificationType.contactPartnerElectionImminent:
      return new TwoLinksNotification(
        { notify_at, type, read_at, id },
        "Der Ansprechpartner",
        data.contactPartner.first_name + " " + data.contactPartner.last_name,
        { name: "user.show", params: { id: data.contactPartner.id } },
        `im ${gremiumKind(data.gremium)}`,
        data.gremium.name,
        { name: "gremium", params: { id: data.gremium.route_slug } },
        "muss bald neu gewählt werden",
        "fa-ballot"
      );
    case notificationType.membershipMessageSent:
      return new MemebershipMessageSentNotification(
        {
          notify_at,
          type,
          read_at,
          id,
        },
        data
      );
    case notificationType.MembershipApplicationSuccesful:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        "Der ",
        "Mitglidschaftsantrag",
        {
          name: "mycompany.membership",
        },
        "für Ihre Firma wurde bestätigt.",
        "fa-industry-windows"
      );
    case notificationType.MemberhsipApplicationDenied:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        "Der ",
        "Mitglidschaftsantrag",
        {
          name: "mycompany.membership",
        },
        "für Ihre Firma wurde abgelehnt.",
        "fa-industry-windows"
      );
    case notificationType.MemberhsipApplicationSubmitted:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        "Die Firma",
        data.company.name1 + " " + (data.company.name2 || ""),
        {
          name: "admin.company.show.sub",
          params: { id: data.company.id, page: "application" },
        },
        "hat einen Mitgliedschaftsantrag gestellt.",
        "fa-industry-windows"
      );
    case notificationType.newTicket:
      return new TicketNotification(
        { notify_at, type, read_at, id },
        "CREATED",
        data.ticket
      );
    case notificationType.ticketUpdated:
      return new TicketNotification(
        { notify_at, type, read_at, id },
        "UPDATED",
        data.ticket
      );
    case notificationType.userAddedToCompany:
      return new TwoLinksNotification(
        { notify_at, type, read_at, id },
        "Der Nutzer",
        data.user.first_name + " " + data.user.last_name,
        { name: "user.show", params: { id: data.user.id } },
        "ist",
        "Ihrer Firma",
        { name: "mycompany" },
        "beigetreten",
        "fa-user-plus"
      );
    case notificationType.commentCreated:
      return new CommentNotification(
        { notify_at, type, read_at, id },
        data.topic.title,
        data.topic.route_slug,
        data.gremium.name,
        data.gremium.route_slug,
        data.comment.id,
        localStorage.getItem("user_id"),
        data.gremium,
        data.replyTo
      );
    case notificationType.topicCreated:
      return new TwoLinksNotification(
        { notify_at, type, read_at, id },
        "Das neue Forumsthema ",
        data.topic.title,
        {
          name: "topic.show",
          params: { id: data.gremium.route_slug, subId: data.topic.route_slug },
        },
        `wurde im ${gremiumKind(data.gremium)}`,
        data.gremium.name,
        { name: "gremium", params: { id: data.gremium.route_slug } },
        "erstellt.",
        "fa-poll-people"
      );
    case notificationType.newsItemCreated:
      if (data.gremium) {
        return new TwoLinksNotification(
          { notify_at, type, read_at, id },
          "Der neue Newsbeitrag ",
          data.news.title,
          {
            name: "gremium.news.show",
            params: {
              id: data.gremium.route_slug,
              subId: data.news.route_slug,
            },
          },
          `wurde im ${gremiumKind(data.gremium)}`,
          data.gremium.name,
          { name: "gremium", params: { id: data.gremium.route_slug } },
          "erstellt.",
          "fa-newspaper"
        );
      } else {
        return new LinkNotification(
          { notify_at, type, read_at, id },
          "Der neue Newsbeitrag ",
          data.news.title,
          { name: "news.show", params: { id: data.news.route_slug } },
          "wurde erstellt",
          "fa-newspaper"
        );
      }
    case notificationType.eventInvite:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        "Sie wurden zur Veranstaltung",
        data.event.name,
        { name: "event.show", params: { id: data.event.route_slug } },
        "am " + moment(data.event.start).format("DD.MM.YYYY") + " eingeladen.",
        "fa-calendar-clock"
      );
    case notificationType.eventOnlineAccessOpen:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        "Die Online-Zugangsdaten zur Veranstaltung",
        data.event.title,
        { name: "event.show", params: { id: data.event.route_slug } },
        "am " +
        moment(data.event.start).format("DD.MM.YYYY") +
        " sind nun freigeschaltet.",
        "fa-calendar-clock"
      );
    case notificationType.eventChanged:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        "Die Veranstaltung",
        data.event.name,
        { name: "event.show", params: { id: data.event.route_slug } },
        "am " +
        moment(data.event.start).format("DD.MM.YYYY") +
        " wurde bearbeitet. Bitte überprüfen Sie Ihre Anmeldungsdaten.",
        "fa-calendar-clock"
      );
    case notificationType.movedUpFromWaitingList:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        "Sie sind von der Warteliste für die Veranstaltung",
        data.event.name,
        { name: "event.show", params: { id: data.event.route_slug } },
        "am " + moment(data.event.start).format("DD.MM.YYYY") + " aufgerückt.",
        "fa-calendar-clock"
      );
    case notificationType.manuallyAddedToEvent:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        "Ein Administrator hat sie als Teilnehmer an der Veranstaltung",
        data.event.name,
        { name: "event.show", params: { id: data.event.route_slug } },
        "am " + moment(data.event.start).format("DD.MM.YYYY") + " hinzugefügt.",
        "fa-calendar-clock"
      );
    case notificationType.eventAnnounced:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        "Eine neue Veranstaltung wurde angekündigt:",
        data.event.name,
        { name: "event.show", params: { id: data.event.route_slug } },
        "am " + moment(data.event.start).format("DD.MM.YYYY") + ".",
        "fa-calendar-clock"
      );
    case notificationType.eventApplicationsOpened:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        "Die Anmeldungen zur Veranstaltung",
        data.event.name,
        { name: "event.show", params: { id: data.event.route_slug } },
        "am " +
        moment(data.event.start).format("DD.MM.YYYY") +
        " sind nun eröffnet.",
        "fa-calendar-clock"
      );
    case notificationType.eventCanceled:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        "Die Veranstaltung",
        data.event.name,
        { name: "event.show", params: { id: data.event.route_slug } },
        "am " +
        moment(data.event.start).format("DD.MM.YYYY") +
        " wurde abgesagt.",
        "fa-calendar-clock"
      );
    case notificationType.custom:
      if (data.text) {
        return new CustomNotification(
          { notify_at, type, read_at, id },
          data.text.subject,
          data.text.rich_text_id,
          data.prepend
        );
      }
      return new CustomNotification(
        { notify_at, type, read_at, id },
        data.subject,
        data.rich_text_id
      );
    case notificationType.eventApplicationSuccesful:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        "Ihre Anmeldung an der Veranstaltung",
        data.event.name,
        { name: "event.show", params: { id: data.event.route_slug } },
        "am " +
        moment(data.event.start).format("DD.MM.YYYY") +
        " war erfolgreich.",
        "fa-calendar-clock"
      );
    case notificationType.eventApplicationCanceled:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        "Sie haben Ihre Anmeldung an der Veranstaltung",
        data.event.name,
        { name: "event.show", params: { id: data.event.route_slug } },
        "am " + moment(data.event.start).format("DD.MM.YYYY") + " abgesagt.",
        "fa-calendar-clock"
      );
    case notificationType.eventStartImminent:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        "Die Veranstaltung",
        data.event.title,
        { name: "event.show", params: { id: data.event.route_slug } },
        "am " +
        moment(data.event.start).format("DD.MM.YYYY") +
        " beginnt in wenigen Tagen.",
        "fa-calendar-clock"
      );
    case notificationType.userShouldCheckProfile:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        "Bitte überprüfen Sie, ob die daten in",
        "Ihrem Nutzerprofil",
        { name: "user.show", params: { id: data.user.id } },
        "noch aktuell sind",
        "fa-user"
      );
    case notificationType.companyShouldCheckProfile:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        "Bitte überprüfen Sie, ob die Daten in",
        "Ihrem Firmenprofil",
        { name: "company.show", params: { id: data.company.id } },
        "noch aktuell sind",
        "fa-industry-windows"
      );
    case notificationType.newContactRequest:
      throw new Error("TODO: not implemented"); //this should link the user sending the request and
    //and give you direct access to button to accept or decline it
    case notificationType.newChatInvite:
      return new AcceptChatInviteNotification(
        { notify_at, type, read_at, id },
        data.inviter,
        data.chat
      );
    case notificationType.newMembershipApplication:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        "Die Firma",
        data.company.name1 + " " + (data.company.name2 || ""),
        {
          name: "admin.company.show.sub",
          params: { id: data.company.id, page: "application" },
        },
        "hat einen Mitgliedschaftsantrag gestellt.",
        "fa-industry-windows"
      );
    case notificationType.CompanyOrphaned:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        "Die Firma",
        data.ticket.name1 + " " + (data.ticket.name2 || ""),
        {
          name: "admin.company.show",
          params: { id: data.ticket.id },
        },
        "ist verwaist.",
        "fa-industry-windows"
      );
    case notificationType.companyMembershipStateChanged:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        "Der mitgliedschaftsstatus der Firma",
        data.company.name1 + " " + (data.company.name2 || ""),
        {
          name: "admin.company.show.sub",
          params: { id: data.company.id, page: "membership" },
        },
        "hat sich geändert.",
        "fa-industry-windows"
      );
    case notificationType.membershipApplicationVoteComplete:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        "Die Abstimmung über die Mitgliedschaft der Firma",
        data.company.name1 + " " + (data.company.name2 || ""),
        {
          name: "admin.company.show.sub",
          params: { id: data.company.id, page: "application" },
        },
        "hat begonnen.",
        "fa-industry-windows"
      );
    case notificationType.membershipApplicationVotesOpen:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        "Die Abstimmung über die Mitgliedschaft der Firma",
        data.company.name1 + " " + (data.company.name2 || ""),
        {
          name: "admin.company.show.sub",
          params: { id: data.company.id, page: "application" },
        },
        "ist beendet.",
        "fa-industry-windows"
      );
    case notificationType.GremiumFunctionaryAdded:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        `Sie wurden als Funktionsträger im ${gremiumKind(data.gremium)}`,
        data.gremium.name,
        { name: "gremium", params: { id: data.gremium.route_slug } },
        "hinzugefügt.",
        "fa-users"
      );
    case notificationType.NewMessageInSupportTicket:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        "Neue Nachricht in der Supportanfrage",
        data.ticket.subject,
        { name: "support.ticket.show", params: { id: data.ticket.id } },
        "",
        "fa-circle-question"
      );
    case notificationType.NewSupportTicket:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        "Neue Supportanfrage: ",
        data.ticket.subject,
        { name: "support.ticket.show", params: { id: data.ticket.id } },
        "",
        "fa-circle-question"
      );
    case notificationType.SupportTicketUpdated:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        "Die Supportanfrage ",
        data.ticket.subject,
        { name: "support.ticket.show", params: { id: data.ticket.id } },
        "wurde bearbeitet.",
        "fa-circle-question"
      );
    case notificationType.TicketAssigned:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        "Ihnen wurde die Supportanfrage ",
        data.ticket.subject,
        { name: "support.ticket.show", params: { id: data.ticket.id } },
        "zugewiesen.",
        "fa-circle-question"
      );
    case notificationType.NewGremiumMember:
      return new TwoLinksNotification(
        { notify_at, type, read_at, id },
        "Der Nutzer ",
        (data.user.first_name + " " + data.user.last_name).trim() + " ",
        { name: "user.show", params: { id: data.user.id } },
        `ist dem ${gremiumKind(data.gremium)}`,
        data.gremium.name,
        { name: "gremium", params: { id: data.gremium.route_slug } },
        "beigetreten.",
        "fa-user-plus"
      );
    case notificationType.GremiumMemberLeft:
      return new TwoLinksNotification(
        { notify_at, type, read_at, id },
        "Der Nutzer ",
        (data.user.first_name + " " + data.user.last_name).trim() + " ",
        { name: "user.show", params: { id: data.user.id } },
        `ist aus dem ${gremiumKind(data.gremium)}`,
        data.gremium.name,
        { name: "gremium", params: { id: data.gremium.route_slug } },
        "ausgetreten. ",
        "fa-user-minus"
      );
    case notificationType.GremiumDeactivated:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        gremiumKindWithArticle(data.gremium),
        data.gremium.name,
        { name: "gremium", params: { id: data.gremium.route_slug } },
        "wurde deaktiviert. (" + data.message + ")",
        "fa-users"
      );
    case notificationType.UserReportUpdated:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        "Ihre Meldung des Inhalts",
        data.link,
        "/" + (data.link ? data.link.split("/").slice(3).join("/") : '#'),
        "wurde bearbeitet. (" + data.report?.action_taken + ")",
        "fa-users"
      );
    case notificationType.UserReportClosed:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        "Ihre Meldung des Inhalts",
        data.link,
        "/" + (data.link ? data.link.split("/").slice(3).join("/") : '#'),
        "wurde geschlossen. (" + data.report?.close_reason + ")",
        "fa-users"
      );
    case notificationType.OldCompanyMebershipApplication:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        "Der Mitgliedschaftsantrag der Firma",
        data.company.name1 + " " + (data.company.name2 || ""),
        {
          name: "admin.company.show.sub",
          params: { id: data.company.id, page: "application" },
        },
        "ist älter als 7 Tage und sollte bearbeitet werden.",
        "fa-industry-windows"
      );
    case notificationType.EventProposalSubmitted:
      return new TwoLinksNotification(
        { notify_at, type, read_at, id },
        "Neuer Veranstaltungsantrag ",
        data.proposal.title,
        { name: "admin.events.sub", params: { page: "proposals" } },
        `im ${gremiumKind(data.gremium)} `,
        data.gremium.name,
        { name: "gremium", params: { id: data.gremium.route_slug } },
        "",
        "fa-calendar-clock"
      );
    case notificationType.UserCanceledEventApplication:
      return new TwoLinksNotification(
        { notify_at, type, read_at, id },
        "Der Nutzer",
        data.user.first_name + " " + data.user.last_name,
        { name: "user.show", params: { id: data.user.id } },
        " hat die Anmeldung zu",
        data.event.name,
        { name: "event.show", params: { id: data.event.route_slug } },
        "am " + moment(data.event.start).format("DD.MM.YYYY") + " storniert.",
        "fa-calendar-clock"
      );
    case notificationType.PartnerProfileBookingSuccesfull:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        "Die Buchung Ihres",
        "Partnerprofils",
        {
          name: "mycompany.partner",
        },
        "war erfolgreich.",
        "fa-handshake"
      );
    case notificationType.MergerForFormerManagers:
      return new TwoLinksNotification(
        { notify_at, type, read_at, id },
        "Die von Ihnen verwaltete Firma ",
        data.oldCompany.name1 + (data.oldCompany.name2) ? " " + data.oldCompany.name2 : "",
        { name: "company.show", params: { id: data.oldCompany.id } },
        " wurde mit der Firma",
        data.newCompany.name1 + (data.newCompany.name2) ? " " + data.newCompany.name2 : "",
        { name: "company.show", params: { id: data.newCompany.id } },
        "Verschmolzen",
        "fa-industry-windows"
      )
    case notificationType.MergerForCurreentManagers:
      return new TwoLinksNotification(
        { notify_at, type, read_at, id },
        "Die Firma ",
        data.newCompany.name1 + (data.newCompany.name2) ? " " + data.newCompany.name2 : "",
        { name: "company.show", params: { id: data.newCompany.id } },
        " ist mit der von Ihnen verwalteten Firma ",
        data.oldCompany.name1 + (data.oldCompany.name2) ? " " + data.oldCompany.name2 : "",
        { name: "company.show", params: { id: data.oldCompany.id } },
        "Verschmolzen",
        "fa-industry-windows"
      )
    case notificationType.GremiumFileUploaded:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        `Die Datei ${data.file.name} wurde im ${gremiumKind(data.gremium)}`,
        data.gremium.name,
        { name: "gremium", params: { id: data.gremium.route_slug } },
        "hochgeladen.",
        "fa-file-image"
      )
    case notificationType.CompanyManagerProbationaryMembership:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        "Die Probemitgliedschaft Ihrer Firma ist bald zu Ende. Sie können",
        "hier",
        { name: "mycompany.requests" },
        "eine Vollmitgliedschaft beantragen",
        "fa-industry-windows"
      )
    case notificationType.GuestUser5DayReminder:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        "Sie sind jetzt seit 5 Tagen als Gatbenutzer im DSAGnet registriert. Beantragen Sie",
        "hier",
        { name: "me.requests" }, "eine Vollmitgliedschaft.",
        "fa-user"
      );
    case notificationType.GuestUser15DayReminder:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        "Sie sind jetzt seit 15 Tagen als Gatbenutzer im DSAGnet registriert. Beantragen Sie",
        "hier",
        { name: "me.requests" }, "eine Vollmitgliedschaft.",
        "fa-user"
      );
    case notificationType.GuestUser2DayReminder:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        "Ihr account als Gastbenutzer läuft in zwei Tagen aus. Wenn die das DSAG-Net weiterhin nutzen wollen, beantragen Sie",
        "hier",
        { name: "me.requests" }, "eine Vollmitgliedschaft.",
        "fa-user"
      );
    case notificationType.MVCompanyProbationaryMembership:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        "Die Probemitgliedschaft der Firma ",
        data.company.name1 + (data.company.name2 ? ' ' + data.company.name2 : ''),
        {
          name: "admin.company.show",
          params: { id: data.company.id },
        },
        "ist bald zu Ende.",
        "fa-industry-windows"
      )
    case notificationType.CompanyMergerEmailChangeRequest:
      return new LinkNotification(
        { notify_at, type, read_at, id },
        "Aufgrund eines Firmenmergers hat sich dei Domain Ihrer mitglidsfirma geändert. Passen sie bitte",
        "hier",
        { name: "me.requests" }, "Ihre E-Mail-Adresse an.",
        "fa-envelope"
      );
    default:
      break;
  }
}
