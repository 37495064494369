import {
  adminRoutes,
  authRoutes,
  companyRoutes,
  eventRoutes,
  generalRoutes,
  gremiumRoutes,
  userRoutes,
  globalNewsRoutes,
  surveyRoutes,
  guestRoutes,
  matchingRoutes,
  legalRoutes,
  miscRoutes,
  partnerRoutes,
} from "./routes/index";

export default ({ authGuard, guestGuard, externalGuard }) => [
  ...externalGuard([...guestRoutes]),
  ...guestGuard([...authRoutes]),
  ...authGuard([
    ...adminRoutes,
    ...companyRoutes,
    ...generalRoutes,
    ...gremiumRoutes,
    ...userRoutes,
    ...eventRoutes,
    ...globalNewsRoutes,
    ...surveyRoutes,
    ...legalRoutes,
    ...matchingRoutes,
    ...partnerRoutes,
  ]),

  ...miscRoutes,
];
