import MainLayout from "@/layout/MainLayout.vue";

export const adminRoutes = [
  // main layout
  {
    path: "/",
    component: MainLayout,
    redirect: { name: "home" },
    children: [
      // ADMIN
      {
        path: "/admin",
        name: "admin.cockpit",
        component: () => import("@/views/admin/Cockpit.vue"),
        meta: { permissions: ["admin.show"] },
      },
      {
        path: "/admin/changelog",
        name: "admin.changelog",
        component: () => import("@/views/admin/ChangeLog.vue"),
        meta: { permissions: ["changeLog.show"] },
      },
      {
        path: "/admin/gremium",
        name: "admin.gremium",
        component: () => import("@/views/admin/gremium/ManageGremien.vue"),
        meta: { permissions: ["gremium.list"] },
        children: [
          {
            path: ":page",
            name: "admin.gremium.sub",
            component: () => import("@/views/admin/gremium/ManageGremien.vue"),
            meta: { permissions: ["gremium.list"] },
          },
        ],
      },
      {
        path: "admin/collaborate",
        name: "admin.collaborate",
        component: () => import("@/views/admin/gremium/ManageCollab.vue"),
        meta: { permission: ["gremium.list"] },
        children: [
          {
            path: ":page",
            name: "admin.collaborate.sub",
            component: () => import("@/views/admin/gremium/ManageCollab.vue"),
            meta: { permission: ["gremium.list"] },
          },
        ],
      },
      {
        path: "/admin/collaborate/project-rooms/create",
        name: "admin.collaborate.create-project-room",
        component: () => import("@/views/gremium/create/CreateProjectRoom.vue"),
        meta: {
          permissions: ["gremium.create"],
        },
      },
      {
        path: "/admin/collaborate/:id/edit",
        name: "admin.collaborate.edit-project-room",
        component: () => import("@/views/gremium/create/CreateProjectRoom.vue"),
        meta: {
          permissions: ["gremium.create"],
        },
      },
      {
        path: "/admin/gremium/create",
        name: "admin.gremium.create",
        component: () => import("@/views/gremium/create/GremiumCreate.vue"),
        meta: {
          permissions: ["gremium.create"],
        },
      },
      {
        path: "/admin/gremium/:id/edit",
        name: "admin.gremium.edit",
        component: () => import("@/views/gremium/create/GremiumCreate.vue"),
        meta: { permissions: ["gremium.update"] },
      },
      {
        path: "/admin/antraege",
        name: "admin.requests",
        component: () => import("@/views/admin/requests/Requests.vue"),
        meta: { permissions: ["admin.show"] },
        children: [
          {
            path: ":page",
            name: "admin.requests.sub",
            component: () => import("@/views/admin/requests/Requests.vue"),
            meta: { permissions: ["admin.show"] },
          },
        ],
      },
      {
        path: "/admin/antrag/:id",
        name: "admin.request",
        component: () => import("@/views/admin/requests/RequestShow.vue"),
        meta: { permissions: ["admin.show"] },
      },
      {
        path: "/admin/support/:id",
        name: "admin.support",
        component: () => import("@/views/admin/requests/AdminSupportShow.vue"),
        meta: { permissions: ["admin.show"] },
      },
      {
        path: "/admin/firmen",
        name: "admin.company.list",
        component: () => import("@/views/company/admin/list/CompanyList"),
        meta: { permissions: ["company.list"] },
      },
      {
        path: "/admin/firma/:id",
        name: "admin.company.show",
        component: () =>
          import("@/views/company/admin/show/AdminCompanyShow.vue"),
        meta: { permissions: ["company.update"] },
        children: [
          {
            path: ":page",
            name: "admin.company.show.sub",
            component: () =>
              import("@/views/company/admin/show/AdminCompanyShow.vue"),
            meta: { permissions: ["company.update"] },
          },
        ],
      },
      {
        path: "/admin/benutzer",
        name: "admin.user.list",
        component: () => import("@/views/user/admin/list/AdminUserList.vue"),
        meta: { permissions: ["user.list"] },
      },
      {
        path: "/admin/benutzer/:id",
        name: "admin.user.show",
        component: () => import("@/views/user/admin/show/AdminUserShow.vue"),
        meta: { permissions: ["user.update"] },
        children: [
          {
            path: ":page",
            name: "admin.user.show.sub",
            component: () =>
              import("@/views/user/admin/show/AdminUserShow.vue"),
            meta: { permissions: ["user.update"] },
          },
        ],
      },
      {
        path: "/admin/export",
        name: "admin.export",
        meta: { permissions: ["admin.export"] },
        component: () => import("@/views/admin/export/AdminExport.vue"),
      },
      {
        path: "/admin/events",
        name: "admin.events",
        component: () => import("@/views/admin/events/AdminEvents.vue"),
        meta: { permissions: ["event.list"] },
        children: [
          {
            path: ":page",
            name: "admin.events.sub",
            component: () => import("@/views/admin/events/AdminEvents.vue"),
            meta: { permissions: ["event.list"] },
          },
        ],
      },
      {
        path: "/admin/event-series/new",
        name: "admin.event-series.create",
        component: () => import("@/views/admin/events/CreateEventSeries.vue"),
        meta: { permissions: ["event.list"] },
      },
      {
        path: "/admin/event-series/:id/edit",
        name: "admin.event-series.edit",
        component: () => import("@/views/admin/events/EditEventSeries.vue"),
        meta: { permissions: ["event.list"] },
      },
      {
        path: "/admin/umfragen",
        name: "admin.surveys.list",
        component: () => import("@/views/admin/surveys/AdminListSurveys.vue"),
        meta: { permissions: ["admin.show"] },
      },
      {
        path: "/admin/umfragen/create",
        name: "admin.surveys.create",
        component: () => import("@/views/admin/surveys/AdminCreateSurveys.vue"),
        meta: { permissions: ["admin.show"] },
      },
      {
        path: "/admin/umfragen/edit/:slug",
        name: "admin.surveys.edit",
        component: () => import("@/views/survey/edit/EditSurvey.vue"),
        meta: { permissions: ["admin.show"] },
      },
      {
        path: "/admin/location/create",
        name: "admin.location.create",
        component: () => import("@/views/location/create/LocationCreate.vue"),
        meta: { permissions: ["eventLocation.create"] },
      },
      {
        path: "/admin/location/:id/edit",
        name: "admin.location.edit",
        component: () => import("@/views/location/edit/LocationEdit.vue"),
        meta: { permissions: ["eventLocation.update"] },
      },
      //{
      //  path: "/admin/roles/manage",
      //  name: "admin.roles.manage",
      //  component: () => import("@/views/roles/show/ListRoles.vue"),
      //  meta: { permissions: ["role.update", "role.create", "role.list"] },
      //},
      {
        path: "/admin/roles/:id",
        name: "admin.roles.details",
        component: () => import("@/views/roles/edit/EditPermissions.vue"),
        meta: { permissions: ["role.update"] },
      },
      {
        path: "/admin/permissions",
        name: "admin.permissions",
        component: () =>
          import("@/views/admin/permissions/ManagePermissions.vue"),
        meta: { permissions: ["permissions.edit"] },
        children: [
          {
            path: ":page",
            name: "admin.permissions.sub",
            component: () =>
              import("@/views/admin/permissions/ManagePermissions.vue"),
            meta: { permissions: ["permissions.edit", "role.update"] },
          },
        ],
      },
      {
        path: "admin/bugs",
        name: "admin.bugs",
        component: () => import("@/views/admin/bugs/BugReportList.vue"),
        meta: { permissions: ["bugs.list"] },
      },
      {
        path: "admin/mail-templates",
        name: "admin.mail-templates",
        component: () => import("@/views/admin/mail/MailTemplateList.vue"),
        meta: { permissions: ["mailLogs.show"] },
      },
      {
        path: "admin/text-elements",
        name: "admin.text-elements",
        component: () => import("@/views/admin/text-elements/List.vue"),
        meta: { permissions: ["mailLogs.show"] },
      },
      {
        path: "admin/bugs/:id",
        name: "admin.bugs.details",
        component: () => import("@/views/admin/bugs/BugReportDetails.vue"),
        meta: { permissions: ["bugs.show"] },
      },
      {
        path: "admin/attribute",
        name: "admin.manage-attributes",
        component: () =>
          import("@/views/admin/attributes/AttributeManagement.vue"),
      },
      {
        path: "admin/message-logger",
        name: "admin.message-logger",
        component: () =>
          import("@/views/admin/message-logger/ListSentMessages.vue"),
        meta: { permissions: ["mailLogs.show"] },
      },
      {
        path: "admin/url-shortner",
        name: "admin.url-shortner",
        component: () =>
          import("@/views/admin/url-shortner/UrlShortnerList.vue"),
        meta: { permissions: ["admin.show"] },
      },
      {
        path: "/admin/reports",
        name: "admin.reports",
        component: () => import("@/views/admin/reports/ReportOverview.vue"),
        meta: { permissions: ["admin.show"] },
        children: [
          {
            path: ":page",
            name: "admin.reports.sub",
            component: () => import("@/views/admin/reports/ReportOverview.vue"),
            meta: { permissions: ["admin.show"] },
          },
        ],
      },
      {
        path: "admin/partner-profiles",
        name: "admin.partner-profiles",
        component: () =>
          import("@/views/admin/partner-db/PartnerDbList.vue"),
        meta: { permissions: ["partnerDb.admin"] },
      },
      {
        path: "admin/partner-profile/:id/edit",
        name: "admin.partner-profile.edit",
        component: () =>
          import("@/views/admin/partner-db/PartnerProfileEdit.vue"),
        meta: { permissions: ["partnerDb.admin"] },
      },
      {
        path: "admin/matching",
        name: "admin.matching",
        component: () =>
          import("@/views/matching/admin/list/AdminMatchingProjects.vue"),
        meta: { permissions: ["partnerDb.admin"] },
      },
      {
        path: "admin/content-pages",
        name: "admin.content-pages",
        component: () =>
          import("@/views/admin/contentPages/List.vue"),
        meta: { permissions: ["contentPages.admin"] },
      },
      {
        path: "admin/content-pages/new",
        name: "admin.content-pages.create",
        component: () =>
          import("@/views/admin/contentPages/Create.vue"),
        meta: { permissions: ["contentPages.admin"] },
      },
      {
        path: "admin/content-pages/edit/:id",
        name: "admin.content-pages.edit",
        component: () =>
          import("@/views/admin/contentPages/Edit.vue"),
        meta: { permissions: ["contentPages.admin"] },
      },
    ],
  },
];
