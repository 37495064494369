<template>
  <notification-layout
    :icon="notification.icon"
    :time="notification.notify_at"
    :notification="notification"
  >
    <p>{{ notification.subject }}</p>
    <b-button variant="clean" @click="showContent">Mehr anzeigen</b-button>
    <div
      :hidden="!contentShown"
      id="bug-reporter-modal___BV_modal_outer_"
      style="position: absolute; z-index: 1040"
    >
      <div
        @click="contentShown = false"
        id="bug-reporter-modal"
        role="dialog"
        aria-labelledby="bug-reporter-modal___BV_modal_title_"
        aria-describedby="bug-reporter-modal___BV_modal_body_"
        class="modal fade show"
        aria-modal="true"
        style="display: block"
      >
        <div class="modal-dialog modal-md">
          <span tabindex="0"></span>
          <div
            id="bug-reporter-modal___BV_modal_content_"
            tabindex="-1"
            class="modal-content"
          >
            <header
              id="bug-reporter-modal___BV_modal_header_"
              class="modal-header"
            >
              <h5 id="bug-reporter-modal___BV_modal_title_" class="modal-title">
                {{ notification.subject }}
              </h5>
              <button
                type="button"
                aria-label="Close"
                class="close"
                @click="contentShown = false"
              >
                ×
              </button>
            </header>
            <div id="bug-reporter-modal___BV_modal_body_" class="modal-body">
              <div
                v-if="richText"
                v-html="
                  (this.notification.prepend || '') + richText.sanitized_html
                "
              ></div>
            </div>
          </div>
          <span tabindex="0"></span>
        </div>
      </div>
      <div
        @click="contentShown = false"
        id="bug-reporter-modal___BV_modal_backdrop_"
        class="modal-backdrop"
      ></div>
    </div>
  </notification-layout>
</template>
<script>
import gql from "graphql-tag";
import NotificationLayout from "./NotificationLayout.vue";
export default {
  components: { NotificationLayout },
  data() {
    return {
      contentShown: false,
    };
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  apollo: {
    richText: {
      query: gql`
        query RichText($richTextId: ID!) {
          richText(id: $richTextId) {
            id
            sanitized_html
          }
        }
      `,
      skip() {
        return !this.contentShown;
      },
      variables() {
        return {
          richTextId: this.notification.content_id,
        };
      },
    },
  },
  methods: {
    showContent(evt) {
      evt.preventDefault();
      evt.stopPropagation();
      this.contentShown = true;
    },
  },
};
</script>