<template>
  <div id="cookie-banner" v-if="coockieBannerShouldBeShown">
    <div id="cookie-banner-content">
      <h1>Diese Webseite benutzt Cookies</h1>
      <p>
        Cookie Erklärung: Lorem ipsum dolor sit amet consectetur adipisicing
        elit. Sed non, iusto dolore temporibus velit beatae facilis vel cumque
        porro nostrum aut recusandae impedit nobis vitae laboriosam sint tenetur
        veritatis voluptatum?
      </p>
      <b-row>
        <b-col cols="6">
          <b-button @click="acceptNeccessaryCookies"
            >Nur notwendige akzeptieren</b-button
          >
        </b-col>
        <b-col cols="6" class="text-right">
          <b-button @click="acceptAllCookies" variant="primary"
            >Alle akzeptieren</b-button
          >
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
export default {
  methods: {
    acceptNeccessaryCookies() {
      setCookie(
        "cookieConsent",
        JSON.stringify({
          kind: "necessary",
          date: this.$formatDateTime(new Date()),
        }),
        90
      );
      window.location.reload();
    },
    acceptAllCookies() {
      setCookie(
        "cookieConsent",
        JSON.stringify({ kind: "all", date: this.$formatDateTime(new Date()) }),
        90
      );
      window.location.reload();
    },
  },
  computed: {
    coockieBannerShouldBeShown() {
      let cookieConsent = getCookie("cookieConsent");
      if (!cookieConsent) return true;
      //In the future we can check for the date here
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
#cookie-banner {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  backdrop-filter: blur(1rem) brightness(60%);
}
#cookie-banner-content {
  position: fixed;
  bottom: 0;
  width: 100%;
  min-height: 200px;
  background-color: white;
  padding: 1.5rem 3rem;
}
</style>