export const miscRoutes = [
  {
    path: "",
    component: () => import("@/layout/MiscLayout.vue"),
    children: [
      {
        path: "/pages/error-404",
        name: "page-error-404",
        component: () => import("@/views/misc/Error404.vue"),
      },
      {
        path: "/transaction",
        name: "transaction",
        component: () =>
          import("@/views/transactional-links/TransactionalLink.vue"),
      },
    ],
  },

  // Redirect to 404 page, if no match found
  { path: "*", component: () => import("@/views/misc/Error404.vue") },
];
