<template>
  <div
    class="notification"
    @click="() => setNotificationRead(notification.id)"
    :class="notification.withDate && !notification.read_at ? 'unread' : ''"
  >
    <div class="notification-icon">
      <span>
        <i :class="'fa-regular ' + icon"></i>
      </span>
    </div>
    <div class="notification-text">
      <slot></slot>
      <div class="notification-text-time">{{ relativeTime }}</div>
    </div>
    <!-- <div v-if="notification.withDate && notification.read_at">
      Gelesen: {{ $formatDateTime(notification.read_at) }}
    </div> -->
  </div>
</template>
<script>
import moment from "moment";
import "moment/locale/de";
import gql from "graphql-tag";
moment.locale("de");

export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    time: {
      type: [Date, String],
      required: true,
    },
    notification: Object,
  },
  computed: {
    relativeTime() {
      return moment(this.time).fromNow();
    },
  },
  methods: {
    setNotificationRead(id) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation MarkAsRead($userId: ID!, $ids: [ID!]) {
              markAsRead(userId: $userId, ids: $ids){
                id
              }
            }
          `,
          variables: {
            userId: localStorage.getItem("user_id"),
            ids: [id]
          },
        })
        .then(({ markAsRead }) =>
          this.$toast(`Benachrichtigungen als gelesen markiert`)
        );
    },
  },
};
</script>
