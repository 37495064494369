if (process.env.VUE_APP_DEBUG !== "local") {
  console.stdlog = console.log.bind(console);
  console.logs = [];
  console.log = function () {
    console.logs.push(["LOG", new Date(), ...Array.from(arguments)]);
    console.stdlog.apply(console, arguments);
  };

  console.stdError = console.error.bind(console);
  console.error = function () {
    console.logs.push(["ERROR", new Date(), ...Array.from(arguments)]);
    console.stdError.apply(console, arguments);
  };

  console.stdInfo = console.info.bind(console);
  console.info = function () {
    console.logs.push(["INFO", new Date(), ...Array.from(arguments)]);
    console.stdInfo.apply(console, arguments);
  };

  console.stdTrace = console.trace.bind(console);
  console.trace = function () {
    console.logs.push(["TRACE", new Date(), ...Array.from(arguments)]);
    console.stdTrace.apply(console, arguments);
  };

  console.stdWarn = console.warn.bind(console);
  console.warn = function () {
    console.logs.push(["WARN", new Date(), ...Array.from(arguments)]);
    console.stdWarn.apply(console, arguments);
  };
}

import Vue from "vue";
import App from "./App.vue";
import "vue-apollo";
import { createProvider } from "./vue-apollo";
import router from "./router";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);
const baseURL = process.env.VUE_APP_API_URL || "http://localhost:8000";
Vue.axios.defaults.baseURL = baseURL;

Vue.config.productionTip = false;

// bootstrap
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

// Scrollbar
import simplebar from "simplebar-vue";
import "simplebar-vue/dist/simplebar.min.css";
Vue.component("simplebar", simplebar);

import VCalendar from "v-calendar";
Vue.use(VCalendar);

import { ValidationProvider, ValidationObserver } from "vee-validate";
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
import UplImage from "@/components/ui/UplImage";
Vue.component("UplImage", UplImage);
import { extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";

for (const [name, rule] of Object.entries(rules)) {
  extend(name, rule);
}

import { localize } from "vee-validate";
import de from "vee-validate/dist/locale/de.json";

localize("de", de);

// custom styling
import "./assets/scss/app.scss";

// font awesome
import "@/plugins/fontawesome/fontawesome.js";

import titleMixin from "./mixins/title";
Vue.mixin(titleMixin);
import formatDateTimeMixin from "./mixins/formatDateTime";
Vue.mixin(formatDateTimeMixin);
import formatDateMixin from "./mixins/formatDate";
Vue.mixin(formatDateMixin);
import toastMixin from "./mixins/toast";
Vue.mixin(toastMixin);
import toastMixinFail from "./mixins/toastFail";
Vue.mixin(toastMixinFail);
import toastMixinWarning from "./mixins/toastWarning";
Vue.mixin(toastMixinWarning);
import linkGen from "./mixins/linkGen";
Vue.mixin(linkGen);
import shortenType from "./mixins/shortenType";
Vue.mixin(shortenType);
import currentDate from "./mixins/currentDate";
Vue.mixin(currentDate);
import logoutMixin from "./mixins/logoutMixin";
Vue.mixin(logoutMixin);
import validateFiles from "./mixins/validateFiles";
Vue.mixin(validateFiles);
import currentDateTime from "./mixins/currentDateTime";
Vue.mixin(currentDateTime);
import translateEventType from "./mixins/translateEventType";
import PermissionManager from "./plugins/permissions/PermissionManager";
import GlobalEvents from "./plugins/GlobalEvents/GlobalEvents";
Vue.mixin(translateEventType);
import generateShortLink from "./mixins/generateShortLink";
Vue.mixin(generateShortLink);

Vue.use(PermissionManager);
Vue.use(GlobalEvents);

new Vue({
  apolloProvider: createProvider(),
  router,
  render: (h) => h(App),
}).$mount("#app");
