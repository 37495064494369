import MainLayout from "@/layout/MainLayout.vue";

export const companyRoutes = [
  // main layout
  {
    path: "/",
    component: MainLayout,
    redirect: { name: "home" },
    children: [
      // FIRMEN
      {
        path: "/firma/:id",
        name: "company.show",
        component: () => import("@/views/company/show/CompanyShow"),
      },
      {
        path: "/firma/:id/mitglieder",
        name: "company.show.member",
        component: () => import("@/views/company/show/CompanyViewMember"),
      },
      {
        path: "/firma/:id/standorte",
        name: "company.show.locations",
        component: () => import("@/views/company/show/CompanyViewLocations"),
      },
      {
        path: "/meine-firma",
        name: "mycompany",
        component: () => import("@/views/my-company/MyCompanyShow"),
      },
      {
        path: "/meine-firma/mitglieder",
        name: "mycompany.members",
        component: () => import("@/views/my-company/show/MyCompanyMember"),
      },
      {
        path: "/meine-firma/antraege",
        name: "mycompany.requests",
        component: () => import("@/views/my-company/show/MyCompanyRequests"),
      },
      {
        path: "/meine-firma/mitgliedschaft",
        name: "mycompany.membership",
        component: () => import("@/views/my-company/show/MyCompanyMembership"),
      },
      {
        path: "/meine-firma/antrag/:id",
        name: "mycompany.request",
        component: () => import("@/views/my-company/show/RequestShow.vue"),
      },
      {
        path: "/meine-firma/standorte",
        name: "mycompany.locations",
        component: () => import("@/views/my-company/show/MyCompanyLocations"),
      },
      {
        path: "/meine-firma/partner-profil",
        name: "mycompany.partner",
        component: () => import("@/views/my-company/show/MyCompanyPartnerProfile"),
      },
      {
        path: "/meine-firma/machting-projekte",
        name: "mycompany.matching",
        component: () => import("@/views/my-company/show/MyCompanyMatchingProjects"),
      },
      {
        path: "/meine-firma/partner-profil/buchen",
        name: "mycompany.partner.booking",
        component: () => import("@/views/my-company/show/MyCompanyPartnerBooking"),
      },
      {
        path: "/meine-firma/antraege/stammdaten",
        name: "mycompany.requests.change-data",
        component: () => import("@/views/my-company/show/CompanyDataTicket"),
      },
      {
        path: "/meine-firma/:page",
        name: "mycompany.sub",
        component: () => import("@/views/my-company/MyCompanyShow"),
      },
      {
        path: "/firma-registrieren/:email?", // "?" at the end makes the param optional
        name: "company.register",
        component: () => import("@/views/company/register/RegisterCompany.vue"),
      },
      {
        path: "/firma-registrieren-erfolgreich",
        name: "company.registered",
        component: () => import("@/views/company/register/Success.vue"),
      },
    ],
  },
];
