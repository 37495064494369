import MainLayout from "@/layout/MainLayout.vue";

export const matchingRoutes = [
  // main layout
  {
    path: "/",
    component: MainLayout,
    redirect: {name: 'home'},
    children: [
      {
        path: "/matching",
        name: "matching",
        component: () => import("@/views/matching/list/MatchingList.vue"),
      },
      {
        path: "/matching/create",
        name: "matching.create",
        component: () => import("@/views/matching/create/MatchingProjectCreate.vue"),
      },
      {
        path: "/matching/:id",
        name: "matching.show",
        component: () => import("@/views/matching/show/MatchingProject.vue"),
      },
      {
        path: "/matching/:id/edit",
        name: "matching.edit",
        component: () => import("@/views/matching/edit/MatchingProjectEdit.vue"),
      },
    ],
  },
];
