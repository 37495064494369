<template>
  <notification-layout
    :icon="notification.icon"
    :time="notification.notify_at"
    :notification="notification"
  >
    <template v-if="permissions['ticket.edit']"
      >Neue Nachricht im
      <router-link
        class="hover-orange"
        :to="{
          name: 'admin.company.show',
          props: { id: notification.data.company.id },
        }"
        >Mitgliedschaftsantrag</router-link
      >
    </template>
    <template v-else
      >Neue Nachricht im
      <router-link class="hover-orange" :to="{ name: 'mycompany.membership' }"
        >Mitgliedschaftsantrag</router-link
      ></template
    >
  </notification-layout>
</template>
<script>
import NotificationLayout from "./NotificationLayout.vue";
export default {
  components: { NotificationLayout },
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  loadPermissions() {
    return ["ticket.edit"];
  },
};
</script>
