var render = function render(){var _vm=this,_c=_vm._self._c;return _c('notification-layout',{attrs:{"icon":_vm.notification.icon,"time":_vm.notification.notify_at,"notification":_vm.notification}},[(
      _vm.notification.replyTo &&
      _vm.notification.user === _vm.notification.replyTo.user_id
    )?[_c('router-link',{staticClass:"hover-orange",attrs:{"to":{
        name: 'topic.show-comment',
        params: {
          id: _vm.notification.gremiumSlug,
          subid: _vm.notification.topicSlug,
          commentId: _vm.notification.commentId,
        },
      }}},[_vm._v("Neue Antwort")]),_vm._v(" auf "),_c('router-link',{staticClass:"hover-orange",attrs:{"to":{
        name: 'topic.show-comment',
        params: {
          id: _vm.notification.gremiumSlug,
          subid: _vm.notification.topicSlug,
          commentId: _vm.notification.replyTo.id,
        },
      }}},[_vm._v("Ihren Kommentar")]),_vm._v(" im "+_vm._s(_vm.notification.gremiumKind)+" "),_c('router-link',{staticClass:"hover-orange",attrs:{"to":{
        name: 'gremium',
        params: {
          id: _vm.notification.gremiumSlug,
        },
      }}},[_vm._v(_vm._s(_vm.notification.gremiumTitle))]),_vm._v(" zum Thema "),_c('router-link',{staticClass:"hover-orange",attrs:{"to":{
        name: 'topic.show',
        params: {
          id: _vm.notification.gremiumSlug,
          subid: _vm.notification.topicSlug,
        },
      }}},[_vm._v(_vm._s(_vm.notification.topicTitle))])]:[_c('router-link',{staticClass:"hover-orange",attrs:{"to":{
        name: 'topic.show-comment',
        params: {
          id: _vm.notification.gremiumSlug,
          subid: _vm.notification.topicSlug,
          commentId: _vm.notification.commentId,
        },
      }}},[_vm._v("Neuer Kommentar")]),_vm._v(" zum Thema "),_c('router-link',{staticClass:"hover-orange",attrs:{"to":{
        name: 'topic.show',
        params: {
          id: _vm.notification.gremiumSlug,
          subid: _vm.notification.topicSlug,
        },
      }}},[_vm._v(_vm._s(_vm.notification.topicTitle))]),_vm._v(" im "+_vm._s(_vm.notification.gremiumKind)+" "),_c('router-link',{staticClass:"hover-orange",attrs:{"to":{
        name: 'gremium',
        params: {
          id: _vm.notification.gremiumSlug,
        },
      }}},[_vm._v(_vm._s(_vm.notification.gremiumTitle))])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }