import PermissionStorage from "./PermissionStorage";
import * as routes from "@/router/routes/index";
import EventManager from "../GlobalEvents/EventManager";
import NamedEventHandler from "../GlobalEvents/NamedEventHandler";

export default {
  install(Vue) {
    /* Janky Hack: We can't get a vm instance or apollo instance in here or
        in the router so we eagerly set a window attribute to a promise, 
        call the actual initialization code from the vue root instance and pass that
        along as well so we can extract it's apollo instance and finally resolve the 
        promise with the vue root object. When checking permissions on the routes we can 
        simply wait that promise and go from there
    */
    let permissionStorage = null;
    let resolveVueRoot;
    let evManager = EventManager.get();
    window.vueRoot = new Promise((resolve) => (resolveVueRoot = resolve));
    Vue.prototype.$initPermissionStorage = async (vueRoot) => {
      let apollo = vueRoot.$apollo;
      resolveVueRoot(vueRoot);
      if (permissionStorage == null) {
        let preloadExtra = Object.entries(routes)
          .filter(([key, _]) => key.endsWith("Routes"))
          .map(([_, value]) => [
            ...value,
            ...value.map((x) => x.children || []),
          ])
          .flat(3)
          .map((route) => (route.meta ? route.meta.permissions : null))
          .filter((x) => x)
          .flat();
        permissionStorage = new PermissionStorage(preloadExtra);
        await permissionStorage.init(apollo);
        evManager.subscribe(
          "login",
          new NamedEventHandler(
            () => permissionStorage.invalidateCache(),
            "invalidate-cache-on-login"
          )
        );
        evManager.subscribe(
          "logout",
          new NamedEventHandler(
            () => permissionStorage.invalidateCache(),
            "invalidate-cache-on-logout"
          )
        );

      }
    };
    Vue.prototype.$invalidatePermissions = ()  => {
      permissionStorage.invalidateCache()
    },
    Vue.prototype.$checkPermission = (key, context = null) => {
      return permissionStorage.checkPermissions([
        { name: key, context: context },
      ]);
    };
    Vue.prototype.$checkAllPermissions = (perms) => {
      return permissionStorage.checkPermissions(
        perms.map((p) => {
          if (typeof p == "string") {
            return { name: p, context: null };
          } else {
            return p;
          }
        })
      );
    };
    Vue.prototype.$checkAnyPermission = (perms) => {
      perms = perms.map((p) => {
        if (typeof p == "string") {
          return { name: p, context: null };
        } else {
          return p;
        }
      });
      permissionStorage.ensurePermissionsLoaded(perms);
      let result = false;
      for (const perm of perms) {
        result ||= permissionStorage.checkPermissionFast(perm);
      }
      return result;
    };
    Vue.prototype.$invalidatePermissionCache = () => {
      return permissionStorage.invalidateCache();
    };
    Vue.mixin({
      data() {
        return {
          permissions: {},
        };
      },
      beforeRouteEnter(to, from, next) {
        next(async (vm) => {
          let requiresPermissions = vm.$options.requiresPermissions;
          if (requiresPermissions) {
            const permissions = await requiresPermissions(to);
            if (permissions) {
              if (!(await vm.$checkAllPermissions(permissions))) {
                vm.$toastFail("Nicht genug Berechtigungen");
                vm.$router.push(-1);
              }
            }
          }
        });
      },
      async beforeCreate() {
        let loadPermissions = this.$options.loadPermissions;
        if (loadPermissions) {
          const permissions = await loadPermissions();
          if (permissions && permissions.length) {
            for (let perm of permissions) {
              this.$checkPermission(perm).then((v) => {
                if (typeof perm === "string") {
                  this.$set(this.permissions, perm, v);
                }else{
                  this.$set(this.permissions, perm.name, v);
                }
              });
            }
          }
        }
      },
    });
  },
};
