export default {
  methods: {
    shortenType(type) {
      if (type == "PROJECT_GROUP") {
        return "PG";
      } else if (type == "TOPIC_GROUP") {
        return "TG";
      } else if (type == "WORKING_CIRCLE") {
        return "AK";
      } else if (type == "WORKING_GROUP") {
        return "AG";
      } else {
        return;
      }
    },
    $gremiumKind(gremium) {
      if (gremium.type === "PROJECT_ROOM") {
        return "Projektraum"
      }
      return "Gremium"
    }
  },
};
