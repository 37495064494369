import MainLayout from "@/layout/MainLayout.vue";

export const surveyRoutes = [
  // main layout
  {
    path: "/",
    component: MainLayout,
    redirect: {name: 'home'},
    children: [
      {
        path: "/surveys",
        name: "surveys",
        component: () => import("@/views/survey/global/list/SurveyList.vue"),
      },
      {
        path: "/survey/:id",
        name: "survey.show",
        component: () => import("@/views/survey/show/SurveyShow.vue"),
      },
      {
        path: "/survey/:id/results",
        name: "survey.show.results",
        component: () => import("@/views/survey/result/show/ResultShow.vue"),
      },
      {
        path: "/survey/:id/participants",
        name: "survey.show.participants",
        component: () => import("@/views/survey/result/show/ParticipantsShow.vue"),
      },
    ],
  },
];
