<template>
  <!-- Right aligned nav items -->
  <b-navbar-nav class="ml-auto nav-actions">
    <b-nav-item
      :to="{ name: 'chat' }"
      v-if="me && me.state === 'NORMAL'"
      style="position: relative"
    >
      <span class="notification-info" v-if="unread_messages">
        <template v-if="unread_messages > 99">99+</template>
        <template v-else>{{ unread_messages }}</template>
      </span>
      <span class="icon-bullet"><i class="fa-light fa-comments"></i></span>

      <span class="nav-item-subtitle">Chat</span>
    </b-nav-item>
    <b-nav-item
      href="#"
      style="position: relative"
      @focusout="maybeCloseNotifications"
      ref="chatNavItem"
    >
      <span class="notification-info" v-if="unreadNotificationCount">
        <template v-if="unreadNotificationCount > 99">99+</template>
        <template v-else>{{ unreadNotificationCount }}</template>
      </span>
      <span class="icon-bullet" @click="openNotifications = !openNotifications"
        ><i class="fal fa-bells"></i
      ></span>
      <span @click="openNotifications = !openNotifications" class="nav-item-subtitle">Inbox</span>
      <Notifications
        v-if="openNotifications"
        :notifications="notifications"
        @close="closeNotifications"
      />
    </b-nav-item>
    <b-nav-item :to="{ name: 'me.favorites' }">
      <span class="icon-bullet"><i class="fal fa-heart"></i></span>

      <span class="nav-item-subtitle">Favoriten</span>
    </b-nav-item>
    <b-nav-item-dropdown right class="hide-mobile">
      <template #button-content>
        <span class="icon-bullet"><i class="fa-light fa-user"></i></span>
        <span class="nav-item-subtitle">Profil</span>
      </template>
      <b-dropdown-item
        :to="{
          name: 'me',
        }"
      >
        Profil
      </b-dropdown-item>
      <b-dropdown-item
        v-if="me && me.company"
        :to="{
          name: 'mycompany',
        }"
      >
        Meine Firma
      </b-dropdown-item>
      <b-dropdown-item href="#" @click="logout()">Logout</b-dropdown-item>
    </b-nav-item-dropdown>
    <b-nav-form class="hide-desktop">
      <span class="icon-bullet" @click="formActive = !formActive"
        ><i class="fal fa-search"></i
      ></span>
      <div v-if="formActive" class="mobile-search">
        <b-form-input
          size="sm"
          class="mr-sm-2"
          placeholder="Suchen"
          @input="searchQueryChange($event)"
          :value="searchQuery"
        >
        </b-form-input>
      </div>
    </b-nav-form>
  </b-navbar-nav>
</template>
<script>
import gql from "graphql-tag";
import Notifications from "./Notifications.vue";

function someParentHasId(element, id) {
  if (element.id === id) {
    return true;
  }
  const parent = element.parentElement;
  if (parent && parent.nodeName !== "HTML") {
    return someParentHasId(parent, id);
  }
  return false;
}

export default {
  components: {
    Notifications,
  },
  emits: ["searchQueryChange"],
  data() {
    return {
      formActive: false,
      openNotifications: false,
      notifications: [],
      notificationInterval: null,
      oldUnreadMessages: 0,
    };
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
    searchQuery: {
      type: String
    }
  },
  methods: {
    closeNotifications() {
      this.openNotifications = false;
    },
    async maybeCloseNotifications(event) {
      event.preventDefault();
      const continueFunction = (target) => {
        if (someParentHasId(target, "notifications-popover")) {
          if (target.nodeType === Node.TEXT_NODE) {
            // When we click a link the original target will be a text node.
            // text nodes can't be focused on bu the link can.
            target = target.parentElement;
          }
          target.focus();
          return;
        }
        this.closeNotifications();
      };
      if (event.explicitOriginalTarget) {
        continueFunction(event.explicitOriginalTarget);
      } else {
        setTimeout(() => continueFunction(document.activeElement), 50);
      }
    },
    logout() {
      this.$logout();
    },
    searchQueryChange(value) {
        this.$emit('searchQueryChange', value);
    },
  },
  computed: {
    unreadNotificationCount() {
      return this.notifications ? this.notifications.length : 0;
    },
  },
  apollo: {
    me: {
      query: gql`
        query Me($filter: NotificationFilter) {
          me {
            id
            state
            company {
              id
            }
            notifications(filter: $filter) {
              data
              id
              read_at
              notify_at
              type
              created_at
            }
          }
        }
      `,
      variables() {
        return {
          filter: {
            read: false,
          },
        };
      },
      update({ me }) {
        this.notifications = me.notifications;
        return me;
      },
    },
    unread_messages: {
      query: gql`
        query Query {
          unread_messages
        }
      `,
      update({ unread_messages }) {
        if (this.oldUnreadMessages < unread_messages) {
          window.shouldRefreshChat = true;
        }
        this.oldUnreadMessages = unread_messages;
        return unread_messages;
      },
    },
  },
  created() {
    this.notificationInterval = setInterval(() => {
      this.$apollo.queries.me.refetch();
      this.$apollo.queries.unread_messages.refetch();
    }, 10000);
  },
  beforeUnmount() {
    clearInterval(this.notificationInterval);
  },
};
</script>
