import AuthLayout from "@/layout/AuthLayout.vue";

export const authRoutes = [
  // auth layout
  {
    path: "/login",
    component: AuthLayout,
    children: [
      {
        name: "login",
        path: "",
        component: () => import("@/views/auth/Login.vue"),
      },
      {
        path: "/password-reset",
        name: "reset.password",
        component: () => import("@/views/auth/ResetPassword.vue"),
      },
      {
        path: "/registrieren",
        name: "user.register",
        component: () => import("@/views/user/register/RegisterUserNew.vue"),
      },
      {
        path: "/registriert",
        name: "user.registered",
        component: () => import("@/views/user/register/Success.vue"),
      },
    ],
  },
];
