<template>
  <notification-layout
    :icon="notification.icon"
    :time="notification.notify_at"
    :notification="notification"
  >
    <template v-if="notification.variant === 'CREATED'"
      >Ein neues Ticket wurde erstellt:
      <router-link :to="ticketUrl">{{
        ticketTypes[notification.ticket.type]
      }}</router-link></template
    >
    <template v-else
      >Es gibt Neuigkeiten zu einem Ticket:
      <router-link :to="ticketUrl">{{
        ticketTypes[notification.ticket.type]
      }}</router-link></template
    >
  </notification-layout>
</template>
<script>
import NotificationLayout from "./NotificationLayout.vue";
import { TicketTypes } from "@/data/enums/TicketType";
export default {
  components: { NotificationLayout },
  props: {
    notification: {
      type: Object,
      required: true,
    },
    ticketTypes: {
      type: Object,
      default: () => TicketTypes,
    },
  },
  computed: {
    ticketUrl() {
      if (this.permissions["ticket.edit"]) {
        return {
          name: "admin.request",
          params: { id: this.notification.ticket.id },
        };
      } else {
        return {
          name: "me.request",
          params: { id: this.notification.ticket.id },
        };
      }
    },
  },
  loadPermissions() {
    return ["ticket.edit"];
  },
};
</script>